@import 'styles/variables';

.headingBlock {
    padding: 0 20px 35px;
    background: #ffff;

    @media (min-width: $tablet) {
        padding: 0 25px;
        background: transparent;
    }

    @media (min-width: $desktop) {
        padding: 0;
    }
}

.heading {
    margin: 0;
    font-size: 24px;
    line-height: 28px;
    color: #393f4a;

    @media (min-width: $tablet) {
        margin: 65px 0 0;
        font-size: 36px;
        line-height: 42px;
    }
}

.text {
    margin: 10px 0 0;
    line-height: 19px;
    color: #707783;
}

.body {
    margin: 35px 20px 50px;

    @media (min-width: $tablet) {
        margin: 35px 25px 50px;
    }

    @media (min-width: $desktop) {
        margin: 35px 0 50px;
    }
}

.container {
    @media (min-width: $tablet) {
        margin: -12.5px;
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: $desktop) {
        margin: -15px;
    }
}

.tile {
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    .icon {
        display: block;
        position: absolute;
        top: 16px;
        left: 16px;
        border-radius: 16px;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    }

    @media (min-width: $tablet) {
        margin: 12.5px;
        flex-basis: calc(50% - 25px);
    }

    @media (min-width: $desktop) {
        margin: 15px;
        flex-basis: 300px;
    }
}
