.filter {
    h5 {
        text-transform: uppercase;
        padding: 0 15px 25px 15px;
        font-size: 12px;
        line-height: 14px;
        color: #707783;
        font-weight: bold;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 10px 15px;
            margin: 0 0 5px 0;
            cursor: pointer;
            border-radius: 19px;
            color: #242f56;
            position: relative;
            width: 185px;

            &.active {
                background: #E4E7EF;

                &:after {
                    position: absolute;
                    right: 8px;
                    top: 9px;
                    display: block;
                    background-image: url('../../assets/check-sm.svg');
                    height: 20px;
                    width: 20px;
                    content: '';
                }
            }
        }
    }
}
