// Colours
$primary: #242F46;
$highlight: #44C9AB;
$progress: #B07F00;
$textAlt: #323741;
$dark: #070F18;
$red: #C94444;
$setup: #263A64;

// Break points
$tabletBreak: 1075px;
$phoneBreak: 767px;
$tinyBreak: 520px;
