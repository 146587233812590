.special-dropdown {
    position: relative;

    .special-dropdown-action {
        background: none;
        border: 1px solid #eee;
        padding: 14px 32px 14px 23px;
        cursor: pointer;
        outline: none !important;
        position: relative;
        border-radius: 4px;

        &.noborder {
            border: none;
        }

        &:after {
            content: '▼';
            position: absolute;
            right: 15px;
            font-size: 10px;
            top: calc(50% - 5px);
        }

        @media (max-width: 420px) {
            padding: 14px 22px 14px 13px;

            &:after {
                right: 5px;
            }
        }
    }

    .special-dropdown-content {
        position: absolute;
        z-index: 9988;
        box-shadow: 2px 0 10px 2px rgba(0,0,0,0.25);
        top: calc(100% + 5px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        min-width: 100%;
        outline: none;
        .special-dropdown-option {
            text-align: left;
            border: none;
            background: #fff;
            margin: 0;
            display: block;
            border-bottom: 1px solid #eee;
            color: #707783;
            padding: 15px 20px;
            cursor: pointer;
            white-space: nowrap;

            &:last-child {
                border-bottom: none;
            }

            &:hover, &.active {
                color: #323741;
            }

            &.active {
                font-weight: bold;
            }
        }
    }


    &.active {
        border: none;
        background: #F7F8FB;

        .special-dropdown-action {
            &:after {
                content: '▲';
            }
        }
    }

}