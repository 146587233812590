.container {
    padding: 20px;
    background: #f6f6f6;
    border-radius: 5px;

    hr {
        margin: 15px 0;
        border: none;
        border-bottom: 1px solid #ebebeb;
    }

    .itemRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
        span:first-child {
            font-weight: 500;
        }
    }

    .priceRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
        span:first-child {
            color: #7b828e;
        }
        span.total {
            font-size: 18px;
            font-weight: bold;
        }
    }
}
