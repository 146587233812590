@import '../colors.scss';

.checkbox {
    background: none;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    padding-bottom: 10px;

    &.full {
        width: 100%;
    }

    .icon {
        width: 26px;
        height: 26px;
        background: none;
        border-radius: 4px;
        margin-right: 16px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        flex: 0;
        flex-basis: 26px;
        border: none;
        display: block;
    }

    &:focus {
        outline: none;
    }

    &.light {
        .icon {
            background: #fff;
            border: 1px solid $primary;
            color: $primary;
        }
    }
}

.checkbox-block {
    display: flex;

    label {
        display: block;
        font-size: 18px;
        color: $primary;
        cursor: pointer;
        flex: 1 1;
        margin-left: 16px;
        position: relative;

        span {
            //font-size: 14px;
            opacity: 0.7;
            display: inline-block;
            margin-left: 10px;

        }
    }
}
