@import '../colors.scss';

.pagination {
display: flex;
list-style: none;
justify-content: center;
> li{
    //margin: 0 10px;
    cursor: pointer;
    color: #969BA5;
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    
    &.active{
        color: #263A64;
        border: solid 1px;
    }

    &.previous, &.next{
        color: #1E3E5F;
    }

    > a {
        text-decoration: none;
        outline: 0;
    }
    
}
}