.signatureContainer {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;

    .clearButton {
        position: absolute;
        top: 6px;
        right: 2px;
        background: #fff;
        padding: 4px 0;
        display: block;
        cursor: pointer;
        font-size: 14px;
        min-width: 60px;
    }

    > canvas {
        border: 1px solid #d8d8d8;
        height: 100%;
        width: 100%;
    }
}

.resizePara {
    text-align: left;

    @media (max-width: 767px) {
        font-size: 14px;
    }
}

.footer {
    button {
        @media (max-width: 767px) {
            font-size: 14px;
            padding: 11px 10px;
        }
    }
}

.error {
    color: red;
}