.container {
    display: flex;
    overflow-y: scroll;
    height: calc(100vh - 78px);
}

.explanation {
    flex-basis: 50%;
    padding: 1em;
}

.example {
    flex-basis: 50%;
    padding: 0;
    background: #fff;
    padding: 1em 0;
    min-height: 100%;

    label {
        margin: 0 2em;

        select {
            margin: 0 1em;
            padding: 0.5em 1em;
            font-size: 15px;
        }
    }

    pre {
        border: none !important;
        margin: 0 !important;
    }
}
