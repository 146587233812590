@import '../colors.scss';

.slim-centre {
    h2 {
        margin-bottom: 30px;
    }
}

.slim-centre-header {
    margin-bottom: 40px;

    a {
        text-decoration: none;
        color: $primary;
        font-size: 16px;
        line-height: 19px;
        position: relative;
        display: block;
        padding-left: 26px;

        &:after {
            content: '';
            background-image: url('../assets/back-arrow-sm.svg');
            position: absolute;
            left: 0;
            background-position: center;
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
        }
    }
}
