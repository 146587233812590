@import 'styles/variables';

$max-width: 960px;

.page {
    flex: auto;
    display: flex;
    flex-direction: column;
}

.main {
    flex: auto;
    width: 100%;
    margin: 0 auto;

    @media (min-width: $desktop) {
        width: $max-width;
    }
}

.footer {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    background: #fff;
    border-top: 1px solid #000;

    @media (min-width: $tablet) {
        border: none;
        box-shadow: 0 -2px 10px -2px rgba(0, 0, 0, 0.1);
    }
}

.footerContent {
    height: 100%;
    padding: 0 20px;

    @media (min-width: $tablet) {
        max-width: $max-width;
        margin: 0 auto;
        padding: 0 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.action {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $tablet) {
        width: auto;
    }
}

.nextBtn {
    width: 140px;
    height: 48px;
    background: #44c9ab;
    border: none;
    border-radius: 24px;
    outline: none;
    color: #242f46;
    font-size: 16px;
    font-weight: 500;
    &:only-child {
        width: 100%;
    }

    @media (min-width: $tablet) {
        width: 180px;
        height: 40px;
        border-radius: 20px;
        &:only-child {
            width: 180px;
        }
    }
}

.prevBtn {
    height: 48px;
    padding: 0;
    background: #fff;
    border: none;
    outline: none;
    color: #323741;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    &::before {
        display: block;
        content: '';
        background-image: url('../assets/chevron-left.svg');
        height: 16px;
        width: 16px;
        background-size: 16px 16px;
    }

    @media (min-width: $tablet) {
        width: 180px;
        height: 40px;
        margin-right: 20px;
        border: 1px solid #263a64;
        border-radius: 20px;
        display: block;
        &::before {
            content: none;
        }
    }
}

.skipBtn {
    display: none;

    @media (min-width: $tablet) {
        display: block;
        color: #393f4a;
        text-decoration: none;
        font-size: 16px;
        line-height: 40px;
    }
}

.skipBtnMobile {
    display: block;
    width: 100%;
    padding: 25px 20px;
    background: #fff;
    font-size: 16px;
    line-height: 19px;
    color: #323741;
    @media (min-width: $tablet) {
        display: none;
    }
}
