@import 'styles/variables';

.page {
    flex: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.content {
    flex: auto;
    margin: 0 auto;
    padding: 35px 20px 15px;
    display: flex;
    flex-direction: column;

    h2 {
        margin: 0 0 10px;
        font-size: 24px;
        color: #393f4a;
    }

    p {
        margin: 0 0 35px;
        line-height: 22px;
        color: #4d5460;
    }

    hr {
        width: 100%;
        height: 1px;
        margin: 30px 0;
        border: none;
        background: #ebebeb;
    }

    @media (min-width: $tablet) {
        padding: 100px 0 80px;
        width: 575px;
    }
}

.section {
    label {
        line-height: 24px;

        .errorLabel {
            display: flex;
            justify-content: space-between;
            .error {
                color: red;
            }
        }
    }

    input,
    textarea {
        display: block;
        width: 100%;
        border: 1px solid #969ba5;
        border-radius: 4px;
        font-size: 16px;

        &:focus {
            outline: none;
            border-color: $primary;
        }

        &.error {
            border-color: red;
        }
    }

    input {
        height: 50px;
        margin: 10px 0 17px;
        padding: 0 15px;

        &.last {
            margin-bottom: 0;
        }
    }

    textarea {
        margin: 10px 0 0;
        padding: 10px 15px;
        line-height: 22px;
        resize: vertical;
    }
}

.split {
    @media (min-width: $tablet) {
        display: flex;
        justify-content: space-between;
        label {
            width: calc(50% - 6px);
        }
    }
}

.taskDescriptions {
    margin: -5px 0;
    > div {
        padding: 5px 0;
    }
}

.reasonHeading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .error {
        margin-bottom: 10px;
        color: red;
    }
}

p.reason {
    margin: 0;
}

.checkboxSection {
    label {
        display: flex;
        align-items: center;

        input {
            flex: none;
            width: 26px;
            height: 26px;
            margin-right: 12px;
            appearance: none;
            outline: none;
            transition: background 200ms ease;
            background: url(../assets/checkbox-unchecked.svg);
            &:checked {
                background: url(../assets/checkbox-checked.svg);
            }
        }
    }
}

p.errorMessage {
    margin: 30px 0 0;
    color: red;
}

.nav {
    margin-top: 35px;

    button {
        width: 100%;
        height: 48px;
        padding: 0;
        border: none;
        border-radius: 24px;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
    }

    button.next {
        margin-bottom: 15px;
        background: #263a64;
        color: #fff;
    }

    button.back {
        background: none;
        color: #263a64;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 14px;
            height: 14px;
            margin-right: 6px;
        }
    }

    @media (min-width: $tablet) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        button {
            flex: none;
        }

        button.next {
            width: 140px;
            margin: 0;
        }

        button.back {
            width: 80px;
            justify-content: left;
        }
    }
}
