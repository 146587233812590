.final-container-row {
    padding: 90px 36px 30px 36px;
}

.order-task.page {
    .show-when-loading {
        display: none;
    }

    .hide-when-loading {
        display: block;
    }

    &.submitting {
        .show-when-loading {
            display: block;
        }

        .hide-when-loading {
            display: none;
        }
    }
}

.payment-summary {
    margin-top: 1px solid #ebebeb;
    padding-top: 20px;

    .sub-heading {
        font-size: 16px;
        line-height: 19px;
        color: #707783;
        margin-bottom: 12px;
    }

    .line-value {
        font-size: 16px;
        line-height: 19px;
        color: #323741
    }

    .line-item-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sub-heading {
            margin: 0;
        }
    }

    .line-item-note {
        text-align: center;
        color: #8a8a8a;
    }

    .total-label {
        color: #263A64;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
    }

    .total-value {
        color: #263A64;
        font-size: 22px;
        line-height: 25px;
        font-weight: 500;
    }

    > hr {
        border-color: #EBEBEB;
        border-style: solid;
        margin: 20px 0;
    }
}
