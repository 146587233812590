@import '../colors.scss';

.orders-page-container {
    background-color: #ffffff;
    .container-fluid {
        max-width: 1280px;
    }
}

.search-bar {
    margin-top: 36px;
    align-items: center;

    .search-field-container {
        display: flex;

        > img {
            background: #f7f8fb;
            display: block;
            cursor: pointer;
            padding: 0 10px;
        }

        > input[type=search] {
            appearance: none;
            background: #f7f8fb;
            color: #323741;
            font-size: 16px;
            line-height: 19px;
            border: none;
            width: 100%;
            padding: 15px 15px 15px 20px;
            outline: none;
        }
        > input[type="search"]::-webkit-search-cancel-button{
            -webkit-appearance:none;
        }
    }

    .filter-selector {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-wrap: wrap;

        @media (max-width: $phoneBreak) {
            margin: 10px 0 0 0;
            justify-content: space-between;
        }
    }
}

.active-filter {
    margin-top: 36px;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    padding: 14px;
}

button.filter-option {
    color: #707783;
    font-size: 16px;
    line-height: 19px;
    background: none;
    border: none;
    display: flex;
    margin-left: 26px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    padding: 6px 2px;

    &:hover {
        color: lighten($primary, 20%);
        border-color: lighten($primary, 20%);
    }

    &.active, &:active {
        color: $primary;
        border-color: $primary;
    }

    &:focus {
        outline: none;
    }
}

.search-result-clearer {
    .button {
        text-transform: none;
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
        padding: 5px;

        > span {
            font-size: 22px;
            line-height: 16px;
            margin-left: 10px;
            display: inline-block;
        }
    }
}

.orders-pagination {
    margin-bottom: 60px;
}

.onboard-popup {

    align-items: center;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    @media (max-width: $phoneBreak) {
        width: 100%;
    }

    .title {
        color: #393F4A;
        font-size: 20px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
        margin-bottom: 40px;
    }

    .description {
        color: #707783;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        width: 350px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 30px;

        @media (max-width: $phoneBreak) {
            width: 100%;
        }
    }

    > img {
        margin-bottom: 20px;
        margin-top: 50px;
    }

    .link {
        margin-top: 60px;
        margin-bottom: 60px;
    > a {
        text-transform: unset;

        &.setup {
            background-color: $setup;
            color: #FFFFFF;
            padding: 13px 68px;
        }
    }
}
}
