.action-result-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 26px 0 36px;
    font-size: 18px;
    line-height: 26px;
  }
}
