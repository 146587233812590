@import '../colors.scss';

.info-tile {
    background-color: #fff;
    box-shadow: 1px 16px 20px 1px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 22px 35px;
    position: relative;
    cursor: pointer;
    height: 162px;
    opacity: 0.5;
    cursor: pointer;

    &.active, &:hover {
        opacity: 1;
    }

    .counter {
        color: #44C9AB;
        font-family: 'Oswald', sans-serif;
        font-size: 68px;
        font-weight: 500;
        line-height: 47px;
        width: 74px;
        flex: 0 0 74px;
        margin-right: 12px;
    }

    .caption {
        color: #323741;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        padding-right: 20px;
        width: 100%;
    }

    .icon {
        display: block;
        position: absolute;
        right: 25px;
        top: 18px;
        width: 32px;
        height: 32px;
    }

    .help-clicker {
        position: absolute;
        right: 25px;
        top: 91px;
        border: none;
        color: $primary;
        background: none;
        //border-radius: 20px;
        height: 30px;
        width: 30px;
        font-weight: bold;
        font-size: 11px;
        cursor: pointer;
        padding: 0;
        margin: 0;
        display: block;

        > img {
            display: block;
            width: 100%;
            height: 100%;
        }

        .tooltip-body {
            font-weight: normal;
            padding: 1em;
            font-size: 14px;
        }
    }
}
