.tile {
    border: 1px solid #ebebeb;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    height: 350px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    transition: box-shadow 200ms ease-out;
    text-decoration: none;

    &.fixed {
        width: 300px;
    }
    &.short {
        height: 320px;
    }
    &.border {
        border: 1px solid #000;
    }

    &:hover {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.15);
    }

    .imgContainer {
        width: 100%;
        height: 120px;
        overflow-y: hidden;

        > img {
            width: 100%;
        }

        &.filter > img {
            //filter: opacity(60%);
        }
    }

    .tileBody {
        padding: 25px 20px;
        height: 230px;
        display: flex;
        flex-direction: column;

        .loadingContainer {
            margin-top: -30px;
        }

        > h3 {
            font-weight: bold;
            color: #242F46;
            margin: 0 0 25px 0;
            padding: 0;
        }

        .tileContentContainer {
            flex: 1 1 auto;
        }

        .tileContent {
            margin: 0;
            color: #707783;
            font-size: 14px;
            line-height: 18px;
            text-overflow: ellipsis;
            overflow-y: hidden;
            height: 36px;
        }

        .price {
            font-size: 14px;
            margin: 12px 0 27px 0;
            color: #393F4A;
            line-height: 16px;
        }
    }

    &.selected {
        border-color: #aaa;
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > a {
            color: #393F4A;
            font-size: 14px;
            line-height: 16px;
        }
    }

    .addRemove {
        > button, a {
            outline: none;
            width: 125px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: inherit;
            font-weight: 500;
            text-align: center;
            padding: 0;
            margin: 0;
            cursor: pointer;
            font-size: 16px;
            line-height: 19px;
            text-decoration: none;

            > img {
                margin-right: 8px;
            }

            &.enquiry {
                background: none;
                border: 1px solid #323741;
                color: #323741;
                text-decoration: none;
            }
        }

        .removeTaskContainer {
            > button {
                display: block;
                color: #393F4A;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                text-decoration: underline;
                background: none;
                border: none;
                outline: none;
                padding: 0;
                margin: 0;
                cursor: pointer;
            }

            .addedDate {
                font-size: 13px;
                line-height: 15px;
                color: #707783;
            }
        }
    }
}
