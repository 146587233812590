.pac-container {
    z-index: 10500;
    border: 1px solid #d5d5d5;
    box-shadow: none;    
  }
  .pac-icon {
    display: none;
  }

  .pac-item {
    height: 50px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    border-color: #d5d5d5;
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;

    &:hover {
      background-color: #f0f3f5;
    }
  }

  .pac-item-query {
    font-size: 16px;
  }

  .pac-matched {
    font-weight: 500;
  }

  .pac-container:after{
    content:none !important;
}