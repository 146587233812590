@import '../colors.scss';

.multiselect {
    .multiselect-item {
        color: $primary;
        background: #F6F6F6;
        width: 100%;
        padding: 15px 18px;
        margin-bottom: 2px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.selected {
            background: rgba(68,201,171,0.18);
        }

        img {
            user-select: none;
        }
    }
}

.form label .multiselect {
    margin-top: 9px;
}
