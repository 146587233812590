.share-report-add-btn {
    width: 100%;
}

.modal-separator {
    margin: 30px -25px; // negative horizontally to offset modal padding
    border: 0;
    border-top: 1px solid #ebebeb;
}

.titled-modal-separator {
    color: #323741;
    margin: 30px -25px; // negative horizontally to offset modal padding
    position: relative;
    width: auto;
    text-align: center;
    font-size: 16px;
    font-weight: normal;

    span {
        background: #fff;
        z-index: 0;
        padding: 0 10px;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        border-top: 1px solid #ebebeb;
        top: 50%;
        z-index: -1;
    }
}

.password-field {
    position: relative;
    > button {
        position: absolute;
        top: 5px;
        right: 2px;
        width: 37px;
        height: 37px;
        border: 0;
        outline: 0;
        background: none;
    }
}

.shared-list {
    max-height: 280px;
    margin: -20px 0;
}

.shared-row {
    padding: 20px 0;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    &:last-child {
        border-bottom: 0;
    }

    > div {
        flex-grow: 1;
        h5 {
            font-size: 18px;
            line-height: 21px;
            color: #242f46;
        }
        p {
            margin: 0;
            font-size: 14px;
            line-height: 16px;
            color: #707783;
        }
    }

    .tooltip-target {
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        > button {
            padding: 15px 10px;
            border: none;
            background: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .link-button {

        }

        .tooltip-action-list {
            margin-top: -10px;
        }
    }

    &.pending {
        opacity: 0.8;
    }
}

span.email-color {
    color: #3e588e;
}

p.modal-explanation {
    color: #707783;
    font-size: 14px;
    line-height: 20px;
    margin: 16px 0;
}

.no-shared-reports {
    text-align: center;
    padding: 80px 0;
}