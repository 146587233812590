label.radio {
    user-select: none;
    cursor: pointer;
    position: relative;
    display: flex;
    padding: 14px 4px 14px 30px;

    > span {
        display: block;
        padding-left: 10px;
    }

    .checker {
        position: absolute;
        background: #fff;
        width: 24px;
        height: 24px;
        border: 1px solid #3e588e;
        border-radius: 50%;
        left: 0;

        &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            background: #3e588e;
            width: 12px;
            height: 12px;
            left: 5px;
            top: 5px;
            display: none;
        }
    }

    input {
        opacity: 0;
        height: 0;
        width: 0;
        cursor: pointer;
        position: absolute;

        &:checked ~ .checker:after {
            display: block;
        }
    }
}