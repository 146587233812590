.tile {
  height: 145px;
  width: 100%;
  max-width: 395px;
  padding: 25px 20px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.borderless {
    border-radius: 0;
    box-shadow: none;
  }
}

.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 19px;
  color: #253a64;
  span {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
  }
}

button.add {
  width: 100%;
  height: 48px;
  padding: 0;
  border: 0;
  border-radius: 24px;
  outline: 0;
  background: #263a64;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
