@import 'styles/variables';

.toast {
    z-index: 999;
    position: fixed;
    top: $nav-height;
    width: 100%;
    padding: 12px 20px;
    background: #0ece77;
    color: #fff;
    display: flex;
    align-items: center;

    &.hide {
        display: none;
    }

    .content {
        flex: auto;
        margin: 0 15px;
    }

    > button {
        padding: 5px;
        background: none;
        border: none;
        outline: none;
        font-size: 26px;
        color: #fff;
    }

    .progress {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 4px;
        background: rgba(0, 0, 0, 0.1);
        animation: dismiss 8s linear;
    }
}

@keyframes dismiss {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}
