@import '../../colors.scss';

.user-list {
    border-top: 1px solid #EBEBEB;
}

.user-list-item {
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 95px;

    @media (max-width: 450px) {
        flex-direction: column;
        justify-content: space-around;
    }

    .user-info {
        .user-name {
            color: $primary;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 10px;
        }

        .user-email {
            color: #707783;
            font-size: 14px;
            line-height: 16px;
        }
    }

    .user-actions {
        display: block;
        text-transform: none;
        padding-right: 0;

        .tooltip-container .tooltip-body .tooltip-action-list .danger {
            color: $red;
        }
    }
}
