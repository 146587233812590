@import 'styles/variables';

.container {
    width: 100%;
    height: 80px;
    padding: 15px;
    border: 1px solid #969ba5;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    &.checked {
        border-color: $primary;
    }
    &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
    .checkbox {
        margin-right: 15px;
        line-height: 0;
    }
    img.newMobile {
        position: absolute;
        top: 0;
        right: 0;
    }

    @media (min-width: $tablet) {
        height: 55px;
        padding: 0 15px;

        img.newMobile {
            display: none;
        }
    }
}

.content {
    .text {
        line-height: 25px;
        color: #393f4a;
    }
    .info {
        display: flex;
        .tooltip {
            width: 25px;
            height: 25px;
            margin-left: 10px;
            border-radius: 50%;
            background: $primary;
            font-weight: 500;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .new {
            display: none;
        }
    }

    @media (min-width: $tablet) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .info .new {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 25px;
            margin-left: 20px;
            background: #0ece77;
            border-radius: 2px;
            font-size: 13px;
            font-weight: bold;
            color: #fff;
        }
    }
}

.tooltipBody {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: normal;
}
