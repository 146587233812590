@import 'styles/variables';

.headingblock {
    padding: 0 20px 35px;
    background: #ffff;

    @media (min-width: $tablet) {
        padding: 0 25px 0;
        background: transparent;
    }

    @media (min-width: $desktop) {
        padding: 0;
    }
}

.heading {
    margin: 0;
    font-size: 24px;
    line-height: 28px;
    color: #393f4a;

    @media (min-width: $tablet) {
        font-size: 36px;
        line-height: 42px;
        margin: 65px 0 0;
    }
}

.text {
    margin: 10px 0 0;
    line-height: 19px;
    color: #707783;
}

.body {
    margin: 35px 20px 50px;

    @media (min-width: $tablet) {
        margin: 35px 25px 50px;
        display: flex;
    }

    @media (min-width: $desktop) {
        margin: 60px 0;
    }
}

.tags {
    margin: -10px;
    display: flex;
    flex-wrap: wrap;
    .tile {
        margin: 10px;
        flex-basis: 100%;
        box-sizing: border-box;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        justify-content: space-between;
        cursor: pointer;
    }
    .active {
        border: 1px solid #393f4a;
    }

    @media (min-width: $tablet) {
        margin: -10px;
        .tile {
            margin: 10px;
            flex-basis: 300px;
            height: 85px;
            width: 300px;
        }
    }
}

.other {
    margin: 0 20px 50px;
    input {
        height: 50px;
        width: 100%;
        padding: 0 15px;
        border: 1px solid #969ba5;
        border-radius: 4px;
        font-size: 16px;
    }

    @media (min-width: $tablet) {
        margin: 0 25px 50px;
        input {
            height: 40px;
            width: 300px;
        }
    }

    @media (min-width: $desktop) {
        margin: 0 0 50px;
    }
}

.othertitle {
    color: #393f4a;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
}
