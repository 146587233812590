@import '../colors.scss';

.order-confirmation {
    > header {
        background-image: url('../assets/check-lg.svg');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 68px 68px;
        padding-left: 100px;
        height: 68px;
        margin: 40px 0 70px 0;

        h1 {
            color: $primary;
            padding: 0;
            margin: 0;
            font-size: 32px;
        }

        h5 {
            margin: 0;
            padding: 0;
        }
    }

    .order-confirmation-body {
        border: 1px solid #CACCD0;
        border-radius: 5px;
        padding: 35px 24px 30px 40px;

        h4 {
            margin: 0 0 20px 0;
            font-size: 16px;
            color: $primary;
        }
    }

    > footer {
        margin-top: 18px;

        > p {
            margin-bottom: 44px;
            text-align: center;
            color: #707783;
        }

        .confirmation-actions {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .button {
                display: block;
            }
        }
    }
}
