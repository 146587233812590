@import 'colors';
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@400;500;700&display=swap');

.page {
    padding: 30px;
    position: relative;

    &.order-task {
        background: #fff;
        min-height: 100%;
        flex: 1;
    }

    &.standalone {
        background: #fff;
        flex: 1;
    }
}

.button {
    text-decoration: none;
    border-radius: 24px;
    padding: 11px 30px;
    cursor: pointer;
    border: none;
    background: none;
    font-family: Roboto; // why isn't this inheriting
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    min-width: 140px;
    text-transform: capitalize;

    &.default {
        border: 1px solid $primary;
        background: $primary;
        color: #fff;
    }

    &.primary {
        border: 1px solid $highlight;
        background: $highlight;
        color: $primary;
    }

    &.boring {
        border: 1px solid $primary;
        background: #fff;
        color: $primary;
    }

    &.disguise {
        border: none;
        font-size: 18px;
        min-width: 50px;
    }

    &.cancel {
        border: none;
        background: $red;
        color: #fff;
    }

    &.informational {
        font-weight: normal;
        text-transform: none;
        border: 1px solid $primary;
        background: $primary;
        color: #fff;
        padding: 11px 20px;
    }

    &.row-button {
        border: none;
        background: #F7F7F7;
        color: $textAlt;
        font-weight: 500;
        height: 45px;
        display: flex;
        align-items: center;
        font-size: 14px;

        > img {
            margin-left: -15px;
            margin-right: 20px;
        }
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.icon-button {
    border: none;//1px solid $primary;
    background: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;

    > .tooltip {
        position: relative;
        display: inline-block;
      }

      > .tooltip .tooltiptext {
        visibility: hidden;
        width: 90px;
        background-color: #323741;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 10px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -45px;
        opacity: 0;
        transition: opacity 0.3s;
        height: 35px;
      }

      > .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }

      > .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
}

.text-button {
    border: none;
    background: none;
    cursor: pointer;
    font-family: Roboto;
}

.form {
    padding: 40px;

    @media (max-width: 399px) {
        padding: 40px 0;
    }

    input, select, textarea {
        display: block;
        padding: 13px 15px;
        margin: 8px auto 15px auto;
        //max-width: 400px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #979797;
        font-size: 16px;
        font-family: Roboto, sans-serif;
        appearance: none;

        &:focus, &:active {
            outline: none;
            border-color: $primary;
        }

        &.error {
            border-color: red;
        }

        &:disabled {
            border: 1px solid #F6F6F6;
            background: #F6F6F6;
        }
    }

    // wrapper for select
    .select {
        position: relative;
        &:after {
            content: '▼';
            position: absolute;
            right: 15px;
            font-size: 10px;
            top: calc(50% - 5px);
        }
    }

    .required {
        font-size: 14px;
        color: red;
    }

    .errorLable {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    label {
        display: block;
        justify-content: flex-start;
        align-items: center;

        > span {
            display: block;
        }
    }

    &.modal-form {
        padding: 0;

        label, input, select, textarea {
            width: 100%;
        }

        textarea {
            resize: vertical;
        }
    }
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    min-height: 100vh;

    > footer {
        flex: 0 0;
        padding: 40px 20px;
        background: $primary;
        color: #fff;
    }
}

.app-body-container {
    padding-top: 78px; // navbar
    height: 100%;
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
}

.banner-section {
    background-color: #F7F8FB;
    padding-top: 67px;
    padding-bottom: 67px;

    .row {
        align-items: center;
    }

    h2, button.special-back {
        color: $primary;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        font-size: 50px;
        line-height: 47px;
        position: relative;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        font-weight: 500;
        text-align: left;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }

        &:after {
            content: '';
            position: absolute;
            background: #CACCD0;
            width: 55px;
            height: 5px;
            display: block;
            left: 0;
            top: -24px;
        }

        &.active:after {
            background: url('./assets/back-arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            height: 26px;
            top: -32px;
        }
    }

    .special-back {
        cursor: pointer;
    }

    .info-tile {
        @media (max-width: 1023px) {
            margin-bottom: 20px;
        }
    }
}

$dtwidth: 140px;
dl {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    margin: 0 0 20px 0;
    color: $primary;

    dt {
        padding: 0;
        margin: 0 0 10px 0;
        font-weight: bold;
        width: $dtwidth;
    }

    dd {
        padding: 0;
        margin: 0;
        width: calc(100% - #{$dtwidth});
    }
}

.tooltip-target {
    position: relative;

    &:focus {
        outline: none;
    }
}

.tooltip-container {
    position: absolute;
    top: calc(100% + 10px);
    color: #fff;
    background: $primary;
    display: none;
    z-index: 990;
    border-radius: 8px;
    box-shadow: 2px 0 10px 2px rgba(0,0,0,0.25);
    min-width: 200px;

    .tooltip-body {
        position: relative;

        &:after {
            position: absolute;
            content: '';
            display: block;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 14px solid $primary;
            width: 0;
            height: 0;
            top: -8px;
        }
    }

    &.top {
        top: auto;
        bottom: calc(100% + 10px);

        .tooltip-body:after {
            border-bottom: none;
            border-top: 14px solid $primary;
            top: auto;
            bottom: -8px;
        }
    }

    &.center {
        left: 50%;
        transform: translateX(-50%);
        .tooltip-body:after {
            left: calc(50% - 14px);
        }
    }

    &.left {
        right: calc(50% - 14px);
        left: auto;

        .tooltip-body:after {
            right: 0;
            left: auto;
        }
    }

    &.right {
        left: calc(50% - 14px);
        right: auto;
    }

    &.active {
        display: block;
    }
}

.tooltip-action-list {
    background: $primary;
    border-radius: 4px;
    width: 100%;

    > a, button {
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px solid #EBEBEB;
        color: #fff;
        padding: 17px 25px;
        text-decoration: none;
        display: block;
        text-align: left;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        &:disabled, .disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }

    &.mobile {
        border-bottom: 1px solid #EBEBEB;
        margin-bottom: 30px;

        @media (min-width: 768px) {
            display: none;
        }
    }
}

.tooltip-container.light {
    color: $primary;
    background: #fff;

    .tooltip-action-list {
        background: #fff;
        > a, button {
            color: $primary;
        }
    }

    .tooltip-body {
        &:after {
            border-bottom-color: #fff;
        }

        &.top:after {
            border-top-color: #fff;
        }
    }
}

h2 {
    color: $primary;
    font-size: 24px;
    margin: 0 0 10px 0;
}

h5 {
    color: #707783;
    font-size: 16px;
    font-weight: normal;
    padding: 0;
    margin: 0;
}

.header-center {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;

    h1, h2, h3, h4, h5 {
        margin: 0;
        padding: 0;
    }
}

.add-more {
    width: 100%;
    height: 55px;
    margin-bottom: 10px;
    padding: 0 20px 0 15px;
    border: 1px dashed #bbb;
    border-radius: 5px;
    background: rgba(235,235,235,.5);
    font-size: 16px;
    color: #393f4a;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
}

.form-error-message {
    color: red;
    margin: 0 0 0 10px;
}

.fluid-container.form {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    &.final {
        max-width: 1440px;
        padding: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .row {
            height: auto;
        }

        > .row {
            margin: 0;
            height: 100%;
        }

        @media (max-width: 1023px) {
            position: static;

            > .row {
                height: auto;
            }
        }
    }

    .row.payment {
        border-radius: 5px;
        background-color: #F6F6F6;
        padding: 20px;
    }

    hr {
        background-color: #EBEBEB;
        border: none;
        height: 1px;
        margin: 20px 0;
    }
}

.credit-card-type {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 7px 0;

    > img {
        width: 38px;
        //height: 26px;
        margin-left: 8px;
        display: block;

        &.inactive {
            opacity: 0.4;
        }
    }
}

p.small {
    color: #353535;
    font-size: 14px;
    text-align: left;
}

.hidden {
    display: none !important;
}

.label-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .row-label {
        color: #242F46;
    }

    .label-row-content {
        color: #242F46;
    }
}
