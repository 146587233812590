@import 'styles/variables';

// most styles for labels and inputs are from the parent component

.container {
    padding: 25px;
    border-radius: 4px;
    background: #f6f6f6;
    h3 {
        margin: 0 0 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #323741;
    }
}

.fields {
    margin-bottom: -17px; // offset input margin

    input.error {
        border-color: red;
    }

    @media (min-width: $tablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
            width: calc(50% - 6px);
        }
        label:only-child {
            width: 100%;
        }
    }
}

.errorLabel {
    display: flex;
    justify-content: space-between;
    .error {
        color: red;
    }
}
