@import '../colors';

.navbar {
    background: $dark;
    height: 78px;
    color: #fff;
    display: flex;
    justify-content: stretch;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9989;

    &.public {
        justify-content: space-between;

        .button-panel {
            display: flex;

            .default {
                background: none;
                border: none;
            }

            a, button {
                padding: 0;
                margin-right: 30px;
                min-width: auto;

                &.primary {
                    padding: 0 30px;
                }
            }
        }
    }

    > h1 {
        margin: 0 20px;
        font-size: 18px;
        font-weight: 500;

        @media (max-width: 460px) {
            display: none;
        }
    }

    .logo {
        border: none;
        background: none;
        display: flex;

        img {
            margin-left: 35px;
        }

        @media (max-width: 460px) {
            background: url('../assets/logo-olasio-o.svg');
            background-size: contain;
            height: 40px;
            width: 40px;
            background-repeat: no-repeat;
            background-position: 50%;
            margin-left: 35px;

            img {
                display: none;
            }
        }
    }

    .special-logo {
        height: 40px;
        margin-left: 35px;
    }

    > nav {
        flex: 1;
        margin-left: 30px;

        a, button {
            color: #fff;
            text-decoration: none;
            display: inline-block;
            margin: 0 20px;
            padding: 12px 0;
            border-bottom: 3px solid transparent;
            cursor: pointer;

            &:hover {
                border-color: #fff;
            }

            &.active {
                border-color: #00CDAA;
            }
        }

        @media (max-width: 767px) {
            a, button { display: none; }
        }
    }

    .special-cancel {
        color: #fff;
        text-decoration: none;
        display: block;
        margin: 0 45px 0 30px;
        font-weight: 500;
        font-size: 16px;
    }

    .nav-title {
        flex: 1;
        color: #fff;
        padding: 0;
        display: block;
        margin: 0 0 0 28px;
        font-weight: 500;
        font-size: 18px;
    }
}

.user-drop-down {
    padding: 0;
    margin: 0 45px 0 30px;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 20px;

    > img {
        display: block;
        //border-radius: 20px;
    }

    &.active {
        box-shadow: 0px 0px 4px rgba(255,255,255,0.5);
    }
}

.nav-button {
    //text-transform: capitalize;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 6px;
    height: 40px;

    > img {
        margin-right: 10px;
    }

    @media (max-width: 410px) {
        display: flex;
        width: 40px;
        text-align: center;
        font-size: 0;
        flex: 0 0 40px;
        padding: 0;
        min-width: 0;

        &.primary {
            color: transparent;
        }

        > img {
            margin: 0;
        }
    }
}

.navbar.public .button-panel {
    .nav-button {
        @media (max-width: 410px) {
            width: auto;
            color: #fff;
            text-align: center;
            font-size: 14px;
            padding: 0 10px;
            margin-right: 10px;

            &.primary {
                color: #242F46;
                padding: 0 10px;
                min-width: 80px;
            }

            > img {
                margin: 0 10px 0 0;
            }
        }
    }
}
