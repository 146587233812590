@import 'styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  background: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet) {
    justify-content: center;
    align-items: center;
  }
}

.content {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  .header,
  .footer {
    flex-shrink: 0;
  }
  .body {
    flex-grow: 1;
  }

  @media (min-width: $tablet) {
    flex: initial;
    width: 490px;
    height: 712px;
    border: 1px solid #ebebeb;
    border-radius: 4px;
  }
}

.header {
  position: sticky;
  top: 0;
  height: 85px;
  padding: 0 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 24px;
    font-weight: bold;
    color: $secondary;
  }

  button {
    padding: 5px;
    line-height: 0;
    border: 0;
    outline: 0;
    background: none;
    img {
      width: 15px;
      height: 15px;
    }
  }
}

.label {
  position: sticky;
  top: 85px;
  padding: 12px 20px;
  background: #f7f8fb;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  color: #707783;
  text-transform: uppercase;
}

ul.list {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    button {
      width: 100%;
      height: 56px;
      padding: 0 20px;
      font-family: inherit;
      font-size: 16px;
      line-height: 19px;
      background: #fff;
      border: none;
      border-bottom: 1px solid #ebebeb;
      outline: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.selected {
      button {
        font-weight: 500;
      }
    }
  }
}

.footer {
  margin: 40px 20px;
  button {
    display: block;
    width: 100%;
    max-width: 335px;
    height: 48px;
    margin: 0 auto;
    padding: 0;
    border: 0;
    border-radius: 24px;
    outline: 0;
    background: #263a64;
    color: #fff;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
  }
}
