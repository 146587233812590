@import '../colors.scss';

.summary-table {
    border-top: 1px solid #ebebeb;

    .summary-group {
        padding: 20px 0;
        border-bottom: 1px solid #ebebeb;
    }

    .summary-item {
        display: flex;
        padding: 5px 0;
        justify-content: space-between;
        align-items: center;

        .label {
            padding-right: 20px;
            color: #323741;
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
        }

        .value {
            text-align: right;
        }

        &.final {
            font-weight: bold;
            margin-top: 20px;
            border-top: 1px solid #ebebeb;

            .label {
                color: $primary;
            }

            .value {
                font-size: 22px;
                color: $primary;
            }
        }
    }

    &.no-borders {
        border: none;
        .summary-group {
            border: none;
        }
    }
}
