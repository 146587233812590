@import '../styles/variables';

.page {
    flex: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.topbar{
    height: 80px;
    background-color: #070F18;
    width: 100%;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    .title {
        color:#FFFFFF;
        font-size: 18px;
        display: none;
        @media (min-width: $tablet) {
            display: block;
            margin-left: 40px;
        }
    }

    div {
        display: flex;
        align-items: center;
    }

    .printpdf {
        border: 1px solid #44C9AB;
        background: #44C9AB;
        color: #242F46;
        padding: 11px 15px 11px 15px;
        text-transform: initial;
        border-radius: 24px;
        text-decoration: none;
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        min-width: 140px;
    }

    @media print {
        display: none;
    }
}

.content {
    flex: auto;
    margin: 0 auto;
    padding: 35px 20px 15px;
    display: flex;
    flex-direction: column;

    .logo {
        padding: 0 0 80px 0;
        text-align: center;
    }

    @media (min-width: $tablet) {
        padding: 100px 0 55px;
        width: 575px;
    }

    p {
        margin: 0;
    }

    .header {
        display: flex;
        justify-content: center;
        margin-bottom: 45px;
        > div {
            h2 {
                font-size: 32px;
                line-height: 28px;
                margin: 0;
            }
            p {
                margin-top: 10px;
                color: #707783;
            }
        }
        @media (min-width: $tablet) {
            justify-content: left;
            margin-bottom: 40px;
            div {
            h2 {
                font-size: 36px;
                line-height: 38px;
            }
        }
        }
    }

    hr {
        margin: 30px 0;
        width: 100%;
        border: none;
        border-bottom: 1px solid #ebebeb;
        @media (min-width: $tablet) {
            margin: 50px 0;
        }
    }

    .ref {
        text-align: left;
        margin-top: 40px;
        @media (min-width: $tablet) {
            text-align: right;
            margin-top: 0;
        }

        @media print {
            text-align: right;
            margin-top: 0;
        }
    }

    .block {
        background: #f6f6f6;
        border-radius: 5px;
        padding: 27px 36px;
        margin-bottom: 15px;

        h3 {
            font-size: 16px;
            margin-bottom: 20px;
            background: rgba(123, 130, 142, 0.1);
            padding: 10px;
        }

        .section {
            display: block;
            //flex-direction: column;
            p {
                //margin-bottom: 8px;
                font-size: 18px;
                font-weight: bold;
                color: #393F4A;
                //margin-right: 30px;
            }
            small {
                display: block;
                margin-top: 15px;
                color: #7b828e;
            }

            .left {
                
                p {
                    margin-bottom: 15px;
                }

                @media (min-width: $tablet) {
                    width: 300px;
                }
            }

            .right {
               
                font-size: 16px;
                color: #06122D;
                width: 200px;
                line-height: 20px;
            }

            @media (min-width: $tablet) {
                    display: flex;
            }
        }

        hr {
            margin: 20px 0;
        }
    }

    .infoBlock {
        display: block;
        p {
            padding: 5px 0;
            color: #6E6E6E;
        }
        .label {
            font-size: 20px;
            font-weight: 500;
            color: #06122D;
        }
        @media (min-width: $tablet) {
            display: flex;
            justify-content: space-between;
            //flex-direction: row-reverse;
        }

        @media print {
            display: flex;
            justify-content: space-between;
        }
    }

    .moreInfo {
        margin: 30px 0 40px;
        text-align: center;
        color: #7b828e;
        a {
            color: #3f6196;
        }
    }

    .nav {
        button {
            width: 100%;
            height: 48px;
        }
        button.next {
            margin-bottom: 15px;
        }
        button.back {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media (min-width: $tablet) {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            button {
                width: calc(50% - 8px);
            }
        }
    }

    .headline {
        display: flex;
        padding: 25px 30px;
        justify-content: space-between;

        .title {
            font-weight: bold;
            font-size: 24px;
            color: #06122D;
            margin-right: 20px;

            @media (min-width: $tablet) {
                margin-right: 100px;
            }

            @media print {
                margin-right: 100px;
            }
        }

        .paymentStatus {
            margin: auto 0;
            background: rgba(51, 192, 93, 0.1);
            border-radius: 2px;
            padding: 8px;
            font-size: 14px;
            color: #33C05D;
        }

        .taskCount {
            margin: auto 0;
            background: rgba(50, 55, 65, 0.1);
            border-radius: 2px;
            padding: 8px;
            font-size: 14px;
            color: #323741;
        }
    }

    .border {
        background: #44C9AB;
        height: 3px;
    }

    .footer {
        padding-top: 40px;
        text-align: center;

        .org {
            margin-bottom: 100px;
        }

        .name {
            font-weight: bold;
            font-size: 16px;
            color: #06122D;
            margin-bottom: 5px;
        }

        .abn {
            font-size: 16px;
            color: #6E6E6E;
            margin-bottom: 12px;
        }

        .address {
            font-size: 16px;
            margin: 5px 0;
            color:#06122D;
        }

        .statement{
            font-size: 12px;
            color: #969BA5;
            margin-bottom: 5px;
        }

        .slogan{
            font-size: 14px;
            color: #3E588E;
            font-style: italic;
            font-family: Arial;
        }

        .footerLogo {
            padding: 0 0 20px 0;
            text-align: center;
        }

        @media print {
            break-before: always;
            break-after: always;
            break-inside: avoid;
            page-break-before: always;
            page-break-after: always;
            page-break-inside: avoid;
        }
    }

    .paymentSummary {
        @media print {
            break-before: always;
            break-after: always;
            break-inside: avoid;
            page-break-before: always;
            page-break-after: always;
            page-break-inside: avoid;
        }
    }

    .orderSummary {
        @media print {
            break-before: always;
            break-after: always;
            break-inside: avoid;
            page-break-before: always;
            page-break-after: always;
            page-break-inside: avoid;
        }
    }
}

