@import '../colors.scss';

.empty-list {

    width: 500px;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top: 128px;

    @media (max-width: $phoneBreak) {
        width: 100%;
    }

    .title {
        color: #393F4A;
        font-size: 20px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
    }

    .description {
        height: 38px;
        width: 490px;
        color: #707783;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        margin-bottom: 50px;

        @media (max-width: $phoneBreak) {
            width: 100%;
        }
    }

    > img {
        margin-bottom: 20px;
    }

    > a {
        text-transform: unset;

        &.setup {
            background-color: $setup;
            color: #FFFFFF
        }
    }
}
