@import '../styles/variables';

.page {
    flex: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.content {
    flex: auto;
    margin: 0 auto;
    padding: 35px 20px 15px;
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet) {
        padding: 100px 0 55px;
        width: 575px;
    }

    p {
        margin: 0;
    }

    .optionsContainer {
        flex: 1 0;
        margin: 35px 0;

        .option {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .nav {
        button {
            width: 100%;
            height: 48px;
        }
        button.next {
            margin-bottom: 15px;
        }
        button.back {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }
        }

        @media (min-width: $tablet) {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            button.next {
                width: 140px;
                margin: 0;
            }
            button.back {
                width: 80px;
                padding-left: 0;
                padding-right: 0;
                justify-content: left;
            }
        }
    }
}
