.landing-body {
    display: flex;
    height: 100vh;
    width: 100vw;
    background: #fff;

    .banner-image {
        height: 100%;
        width: 35%;
        background: url('../assets/login-banner.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 125px;
        }

        @media (max-width: 990px) {
            img {
                height: 100px;
            }
        }

        @media (max-width: 820px) {
            img {
                height: 80px;
            }
        }
    }

    .main-content {
        height: 100%;
        overflow-y: auto;
        width: 65%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        h1 {
            color: #393F4A;
            font-size: 24px;
            font-weight: bold;
            line-height: 28px;
            text-align: center;
            margin: 0 0 10px 0;
        }

        h2 {
            color: #393f4a;
            font-size: 24px;
            line-height: 28px;
            margin: 0 0 8px;
        }

        p {
            color: #707783;
            line-height: 24px;
            margin: 0 0 28px;
        }

        .container {
            width: calc(100% - 2rem);
        }
    }

    .content-header {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .back-link {
            display: flex;
            align-items: center;
            color: #707783;
            text-decoration: none;
            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                margin-right: 12px;
                border: 1px solid #707783;
                border-radius: 50%;
            }
        }

        p {
            margin: 0;
        }
    }

    .content-container {
        width: 90%;
        max-width: 600px;
        margin: 0 auto;
    }

    @media (max-width: 700px) {
        display: block;
        height: auto;

        .banner-image {
            width: 100%;
            height: 110px;
            margin-bottom: 0;

            img {
                height: 60px;
            }
        }

        .main-content {
            height: auto;
            width: 100%;

            .content {
                height: auto;
            }
        }

        .content-header {
            padding: 20px;
            display: block;

            > a.back-link {
                justify-content: center;
                margin-bottom: 10px;
                font-size: 13px;
                text-align: center;

                > div {
                    width: 22px;
                    height: 22px;
                }
            }

            > p {
                font-size: 13px;
                text-align: center;
            }
        }

        .content-container {
            margin-top: 50px;
        }
    }
}

.login-options {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > a {
        display: block;
        color: #004dd1;
    }

    @media (max-width: 374px) {
        > a {
            text-align: right;
        }
    }
}
