.container {
    padding: 35px 30px 45px 30px;
    background: #f6f6f6;
    border-radius: 5px;
    margin-bottom: 30px;

    hr {
        margin: 15px 0;
        border: none;
        border-bottom: 1px solid #ebebeb;
    }

    .itemRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
        span:first-child {
            font-weight: 500;
        }
        .price{
            color: #7B828E;
        }
    }

    .priceRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        color: #7B828E;
        &:last-child {
            margin-bottom: 0;
        }
        span:first-child {
            color: #7b828e;
        }
        span.total {
            font-size: 20px;
            font-weight: bold;
            color: #393F4A;
        }
    }
}
