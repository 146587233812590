@import '../../colors.scss';

.modal-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9990;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.85);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: none;

    &.active {
        display: flex;
    }
}

.modal-window {
    z-index: 9999;
    position: relative;
    background: #fff;
    border: 1px solid #CACCD0;
    box-shadow: 4px 6px 12px 0 rgba(0,0,0,0.21);
    border-radius: 4px;
    padding: 30px 25px;
    max-width: 512px;
    width: 80vw;

    .closer {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: -35px;
        font-size: 40px;
        font-weight: 300;
        line-height: 20px;
        background: none;
        border: none;
        display: block;
        text-align: center;
    }

    header > h2 {
        margin: 0 0 20px 0;
        padding: 0;
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;

        > .button {
            height: 45px;
        }
    }

    .operation{
        > span{
            color: #354F69;
            font-size: 14px;
            font-weight: bold;
            line-height: 35px;
            text-decoration: underline;
            cursor: pointer;
            position: absolute;
            right: 27px;
            top: 30px;
        }
    }
    .reissue-instruction{
        background-color: #FCF5F5;
        color: #C94444;
        padding: 20px;
    }
}
