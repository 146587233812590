@import '../styles/variables';

.page {
    flex: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.content {
    flex: auto;
    margin: 0 auto;
    padding: 35px 20px 15px;
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet) {
        padding: 100px 0 55px;
        width: 575px;
    }

    p {
        margin: 0;
    }

    .header {
        display: flex;
        > div {
            margin-left: 30px;
            h2 {
                font-size: 24px;
                line-height: 28px;
                margin: 0;
            }
            p {
                margin-top: 10px;
                color: #707783;
            }
        }
        @media (min-width: $tablet) {
            h2 {
                font-size: 32px;
                line-height: 38px;
            }
        }
    }

    hr {
        margin: 30px 0;
        width: 100%;
        border: none;
        border-bottom: 1px solid #ebebeb;
        @media (min-width: $tablet) {
            margin: 50px 0;
        }
    }

    .ref {
        margin-bottom: 30px;
    }

    .block {
        background: #f6f6f6;
        border-radius: 5px;
        padding: 27px 36px;
        margin-bottom: 15px;

        h3 {
            font-size: 16px;
            margin-bottom: 20px;
        }

        .section {
            display: flex;
            flex-direction: column;
            p {
                margin-bottom: 8px;
            }
            small {
                display: block;
                margin-top: 15px;
                color: #7b828e;
            }
        }

        hr {
            margin: 20px 0;
        }
    }

    .moreInfo {
        margin: 30px 0 40px;
        text-align: center;
        color: #7b828e;
        a {
            color: #3f6196;
        }
    }

    .invoice {
        margin: 30px 0 20px;
        text-align: center;
        color: #7b828e;
        a {
            color: #3f6196;
        }
    }

    .nav {
        button {
            width: 100%;
            height: 48px;
        }
        button.next {
            margin-bottom: 15px;
        }
        button.back {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media (min-width: $tablet) {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            button {
                width: calc(50% - 8px);
            }
        }
    }
}
