// breakpoints
$tablet: 768px;
$desktop: 992px;
$large-desktop: 1200px;

$nav-height: 78px;

// colours
$primary: #242f46;
$secondary: #323741;
