@import 'styles/variables';

$max-width: 875px;
$max-width-large: 1175px;

.banner {
  padding: 40px 20px 25px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: $nav-height;
  h1 {
    margin: 0;
    font-family: 'Bebas Neue';
    font-size: 38px;
    font-weight: 500;
    line-height: 56px;
    color: $primary;
    text-transform: uppercase;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 33px;
      height: 3px;
      background: #caccd0;
    }
  }

  @media (min-width: $tablet) {
    padding: 40px 25px 25px;
    h1 {
      font-size: 68px;
      line-height: 102px;
      &::before {
        width: 55px;
        height: 5px;
      }
    }
  }

  @media (min-width: $desktop) {
    padding: 68px 0 50px;
    // unset sticky banner
    display: block;
    position: static;
    top: auto;
    h1 {
      max-width: $max-width;
      margin: 0 auto;
    }
  }

  @media (min-width: $large-desktop) {
    h1 {
      max-width: $max-width-large;
    }
  }
}

.loadingContainer {
  margin: auto;
  text-align: center;
  padding-right: 50px;
}

.filterBtn {
  width: 100px;
  height: 40px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  outline: none;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $secondary;
  img {
    margin-right: 8px;
  }

  @media (min-width: $desktop) {
    display: none;
  }
}

.body {
  margin: 35px 20px 50px;

  @media (min-width: $tablet) {
    margin: 35px 25px 50px;
  }

  @media (min-width: $desktop) {
    max-width: $max-width;
    margin: 60px auto 135px;
    display: flex;
    width: 100%;
  }

  @media (min-width: $large-desktop) {
    max-width: $max-width-large;
  }
}

.filter {
  display: none;
  flex: 0 0 auto;

  @media (min-width: $desktop) {
    display: block;
    position: sticky;
    align-self: flex-start;
    top: calc(#{$nav-height} + 60px);
    margin-right: 50px;
    hr {
      margin: 25px 0;
      border: none;
      border-top: 1px solid #ebebeb;
    }
  }
}

.taskContainer {
  display: block;
  flex: 1 1 auto;
  margin: -10px;

  h2 {
    text-transform: uppercase;
    font-family: "Bebas Neue";
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    padding: 0 0 0 10px;

    @media (min-width: $tablet) {
      padding: 0 0 0 12.5px;
    }

    @media (min-width: $desktop) {
      padding: 0 0 0 10px;
    }
  }

  > h2 {
    margin-top: 32px;
  }

  @media (min-width: $tablet) {
    margin: -12.5px;
  }

  @media (min-width: $desktop) {
    margin: -10px;
  }
}

.tasks {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;

  .tile {
    margin: 10px;
    flex-basis: 100%;
  }

  @media (min-width: $tablet) {
    .tile {
      margin: 12.5px;
      flex-basis: calc(50% - 25px);
    }
  }

  @media (min-width: $desktop) {
    .tile {
      margin: 10px;
      flex-basis: 300px;
    }
  }
}

.featuredTasks {
  position: relative;
  background: #E4E7EF;
  padding: 24px 6px 14px 6px;
  margin: 0 -6px 14px;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    background-image: url('../assets/fave-corner-top-right.svg');
    width: 56px;
    height: 50px;
    right: 0;
    top: 0;
  }
}