.loading {
    text-align: center;

    .loading-label {
        padding: 10px;
        color: #666;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
    }

    svg {
        width: 3.75em;
        height: 3.75em;
        transform-origin: center;
        animation: rotate 2s linear infinite;
        text-align: center;
        display: block;
        margin: 20px auto;

        circle {
            fill: none;
            stroke: #1cde99;
            stroke-width: 2;
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
    
@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dashoffset: -125px;
    }
}