@import '../styles/variables';

.page {
    flex: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.content {
    flex: auto;
    margin: 0 auto;
    padding: 35px 20px 15px;
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet) {
        padding: 100px 0 55px;
        width: 575px;
    }

    h2 {
        margin-bottom: 15px;
    }

    p {
        margin: 20px 0;
    }

    hr {
        width: 100%;
        margin: 40px 0 20px;
        border: none;
        border-top: 1px solid #ebebeb;
    }

    p.small {
        font-size: 14px;
        text-align: left;
        a {
            color: #3f6196;
        }
    }

    .termsSection {
        margin: 15px 0 25px;
    }

    .error {
        color: #c94444;
    }

    .nav {
        button {
            width: 100%;
            height: 48px;
        }
        button.next {
            margin-bottom: 15px;
        }
        button.back {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }
        }

        @media (min-width: $tablet) {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            button.next {
                width: 190px;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 250ms ease-in;
            }
            button.back {
                width: 80px;
                padding-left: 0;
                padding-right: 0;
                justify-content: left;
            }
        }
    }
}
