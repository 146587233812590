.header {
    margin: 35px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
    }

    .icons {
        img {
            margin-left: 8px;
        }
        img.inactive {
            opacity: 0.4;
        }
    }
}

.form {
  padding: 0;

  .triple {
    display: flex;
    justify-content: space-between;

    label {
      width: calc(33.33% - 10px);
    }
  }
}
