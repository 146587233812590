@import 'styles/variables';

$max-width: 960px;

.page {
  background: #fff;
  h1 {
    margin: 0 0 20px;
    font-size: 24px;
    line-height: 28px;
    color: $secondary;
  }
  p {
    margin: 0;
    line-height: 22px;
    color: #707783;
  }
  h2 {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    color: #393f4a;
  }
  hr {
    margin: 30px 0;
    border: 0;
    border-top: 1px solid #ebebeb;
  }

  @media (min-width: $tablet) {
    hr {
      margin: 40px 0;
    }
  }

  @media (min-width: $desktop) {
    h1 {
      margin-bottom: 35px;
      font-size: 36px;
      line-height: 42px;
    }
    hr {
      margin: 50px 0;
    }
  }
}

.nav {
  margin: 30px 20px 15px;
  button {
    padding: 10px 0;
    background: none;
    border: 0;
    outline: 0;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
  }

  @media (min-width: $tablet) {
    margin: 45px 25px 25px;
  }

  @media (min-width: $desktop) {
    width: $max-width;
    margin: 45px auto;
  }
}

.content {
  margin: 0 20px;
  .aside {
    display: none;
  }

  @media (min-width: $tablet) {
    margin: 0 25px;
    display: flex;
    .details {
      flex: auto;
    }
    .aside {
      flex: 0 0 300px;
      display: block;
      margin-left: 45px;
    }
  }

  @media (min-width: $desktop) {
    width: 960px;
    margin: 0 auto;
    .aside {
      flex-basis: 390px;
    }
  }
}

.sampleBtn {
  width: 100%;
  height: 45px;
  margin-top: 30px;
  border: 1px solid #393f4a;
  border-radius: 22.5px;
  color: #393f4a;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    margin-right: 10px;
  }

  @media (min-width: $tablet) {
    display: none;
  }
}

ul.features {
  margin: 20px 0 0;
  padding: 0;
  color: #393f4a;
  li {
    list-style: none;
    height: 68px;
    margin-bottom: 5px;
    background: rgba(0, 205, 170, 0.1);
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      margin: 0 18px;
    }
    .feature {
      display: flex;
      flex-direction: column;
      .featureGroup {
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 14px;
      }
      .featureText {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }
    }
  }

  @media (min-width: $desktop) {
    margin: 35px -5px -5px;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 168px;
      height: 160px;
      margin: 5px;
      padding: 23px 16px 0;
      flex-direction: column;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 5px;
      }
      img {
        margin: 0 0 30px;
      }
    }
  }
}

ul.includes {
  margin: 20px 0 40px;
  padding: 0;
  font-size: 16px;
  line-height: 19px;
  color: #707783;
  list-style: inside;
  li {
    margin-bottom: 10px;
  }

  @media (min-width: $tablet) {
    li {
      margin-bottom: 15px;
    }
  }

  @media (min-width: $desktop) {
    margin-bottom: 80px;
    column-count: 2;
  }
}

.suggestions {
  padding: 40px 0 60px;
  background: #f7f8fb;
  > h2 {
    margin: 0 0 25px 20px;
  }
  .tasks {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    > div {
      scroll-snap-align: center;
      padding: 0 10px;
    }
    > div:first-child {
      padding-left: 20px;
    }
    > div:last-child {
      padding-right: 20px;
    }
  }

  @media (min-width: $tablet) {
    padding: 65px 0 75px;
    > h2 {
      margin: 0 0 30px 25px;
    }
    .tasks {
      scroll-snap-type: none;
      > div {
        scroll-snap-align: none;
        padding: 0 15px;
      }
      > div:first-child {
        padding-left: 25px;
      }
      > div:last-child {
        padding-right: 25px;
      }
    }
  }

  @media (min-width: $desktop) {
    padding: 80px 0 100px;
    > h2 {
      max-width: $max-width;
      margin: 0 auto 30px;
    }
    .tasks {
      max-width: $max-width;
      margin: 0 auto;
      overflow-x: visible;
      justify-content: space-between;
      > div {
        padding: 0;
      }
      > div:first-child {
        padding-left: 0;
      }
      > div:last-child {
        padding-right: 0;
      }
    }
  }
}

.stickyFooter {
  position: sticky;
  bottom: 0;
  border-top: 1px solid #000;

  @media (min-width: $tablet) {
    display: none;
  }
}

.removeTile {
  height: 90px;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    color: #393F4A;
    text-decoration: underline;
    cursor: pointer;
  }
  span {
    font-size: 13px;
    line-height: 15px;
    color: #707783;
  }

  @media (min-width: $tablet) {
    height: 145px;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

.sampleBlock {
  height: 480px;
  margin: 30px 0 40px;
  border: 1px solid #ebebeb;
  overflow: hidden;
  position: relative;

  @media (min-width: $desktop) {
    height: 545px;
    margin: 60px 0 80px;
  }
}

.sampleReport {
  position: absolute;
  width: 1280px;
  height: 2050px; // height needs to match the scaling ratio
  border: 0;
  transform: scale(0.2343); // 300 / 1280
  transform-origin: 0 0;

  @media (min-width: $desktop) {
    transform: scale(0.3046); // 390 / 1280
    height: 1790px;
  }
}

.sampleReportButton {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 200px;
  height: 40px;
  margin: 0 auto 25px;
  border: 1px solid #393f4a;
  border-radius: 20px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #393f4a;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    margin-right: 10px;
  }
}
