@import '../../colors.scss';

.order-row-media-wrapper {
    .order-row {
        display: none;
    }
    @media (min-width: 1100px) {
        .order-row {
            display: block;
        }
        .order-row-sm {
            display: none;
        }
    }
}

.order-list {
    margin: 26px 0 60px 0;
}

.order-row {
    background: #fff;
    box-shadow: 3px 6px 11px 5px rgba(0,0,0,0.06);
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    padding: 32px 40px 24px 78px;
    margin-bottom: 20px;
    position: relative;
    @media (max-width: $tinyBreak) {
        padding: 20px 30px;
    }
}

.order-row-info {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $tabletBreak) {
        flex-direction: column;
    }

    > h4 {
        color: #707783;
        font-weight: 400;
        font-size: 15px;
        margin: 0;
        padding: 0;
        width: 200px;
        position: relative;

        &:after {
            border: 4px solid $textAlt;
            display: block;
            width: 14px;
            height: 14px;
            background: none;
            border-radius: 14px;
            margin-right: 26px;
            content: '';
            position: absolute;
            left: -37px;
            top: calc(50% - 7px);
        }

        @media (max-width: $tinyBreak) {
            width: 100%;

            &:after {
                left: 95%;
            }
        }

        &.inprogress:after {
            border-color: $progress;
        }
        &.completed:after {
            border-color: #00B034;
        }
    }

    .info-items {
        display: flex;

        @media (max-width: $phoneBreak) {
            flex-direction: column;
        }

        .info-item {
            width: 250px;
            color: #323741;
            > span{
                font-size: 18px;
                font-weight: 500;
            }

            &.email{
                width: 350px;
            }
            &.name {
                width: 200px;
            }
        }

        .info-date {
            color: #707783;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .order-row-status {
        font-size: 16px;
        width: 180px;
        font-weight: 500;
        color: $textAlt;
        text-align: right;

        @media (max-width: $tabletBreak) {
            text-align: left;
        }

        &.completed {
            color: #00B034;
        }

        &.inprogress {
            color: $progress;
        }

       > .button{
        height: 40px;
        font-size: 14px;
        border: 1px solid #263A64;
        background: #fff;
        color: #323741;
        padding: 0px 15px;
        font-weight: 600;
        }
    }
}

.order-row-separator {
    border: 0;
    border-top: 1px solid #ebebeb;
    margin: 30px 0px;
}

.order-row-attention{
    position: absolute;
    top: -8px;
    right: -5px;
}

.order-row-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .primary-actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .secondary-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > a, > button {
            margin-left: 40px;
        }

        > .text-button {
            width: 100px;
            text-align: right;
            padding: 0;
            border-bottom: 1px solid transparent;

            &:focus {
                outline: none;
            }

            > img {
                display: inline-block;
                width: 16px;
                margin-bottom: -4px;
            }
        }
    }

    @media (max-width: $phoneBreak) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .primary-actions {
            width: 100%;

            > button { width: 100% }
        }

        .secondary-actions {
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;

            > button, > a {
                margin-top: 20px;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.subject-list {
    margin: 20px -40px -24px -78px;
    border-radius: 8px;
    background: #fafafa;

    @media (max-width: $tinyBreak) {
        margin: 20px -30px -20px -30px;
    }

    .subject-item {
        border-bottom: 1px solid #d8d8d8;
        height: 82px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 46px 0 84px;

        @media (max-width: $phoneBreak) {
            padding: 10px 20px;
        }

        &.inactive {
            opacity: 1;
        }

        &:last-child {
            border: none;
        }

        > button {
            height: 40px;
            font-size: 14px;

            @media (max-width: $phoneBreak) {
                height: auto;
                font-size: 13px;
                padding: 8px 12px;
            }
        }

        .description {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            > i {
                display: block;
                border: 4px solid #323741;
                width: 14px;
                height: 14px;
                background: none;
                border-radius: 14px;
                margin-right: 26px;

                &.completed, &.ready {
                    border: 4px solid #33C05D;
                }

                &.inprogress {
                    border: 4px solid $progress;
                }
            }

            > span {
                display: block;

                .fullDescription {
                    font-size: 12px;
                    padding: 0;
                    margin: 0;
                    color: #777;
                }
            }
        }
    }

    .reissue-link{
        color: #323741;
        font-size: 16px;
        cursor: pointer;
        text-decoration: underline;
        margin:0 60px 0 20px;
    }

    .report-block{
        display: flex;
        align-items: center;
    }

    .tag{
        background: #FCF5F5
      }

    .tagdescription{
        margin-left: 40px;
        color:#C94444;
        line-height: 18px;
        font-size: 14px;
        margin-top: 2px;
    }
}

.archived {
    .order-row-info {
        opacity: 0.6;
    }
}

// mobile styles

.order-row-sm {
    margin-bottom: 20px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 3px 6px 11px 5px rgba(0,0,0,0.06);
    position: relative;

    .order-row-attention{
        position: absolute;
        top: -14px;
        right: -10px;
    }
}

.order-row-sm-header {
    height: 55px;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .order-row-sm-dot {
        width: 14px;
        height: 14px;
        margin-left: 20px;
        border: 4px solid $textAlt;
        border-radius: 14px;
        &.inprogress {
            border-color: $progress;
        }
        &.completed {
            border-color: #00B034;
        }
    }

    .order-row-sm-status {
        margin-right: 13px;
        font-size: 16px;
        font-weight: bold;
        color: $textAlt;
        &.inprogress {
            color: $progress;
        }
        &.completed {
            color: #00B034;
        }

        > .button{
            border: 1px solid #263A64;
            background: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: #323741;
            padding: 10px 16px;
        }
    }
}

.order-row-sm-info {
    margin: 0 20px;
    padding: 30px 0;
    border-bottom: 1px solid #ebebeb;

    > h3 {
        margin: 0 0 25px;
        font-size: 18px;
        font-weight: 500;
    }

    > .info-contact {
        margin: 0 0 25px;
        font-size: 16px;
        line-height: 19px;
        span {
            font-weight: 500;
        }
    }

    > .info-ref {
        font-size: 14px;
        line-height: 16px;
        color: #707783;
    }
}

.order-row-sm-actions {
    height: 80px;
    margin: 0 20px;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button.left-action-btn {
        width: 40px;
        height: 40px;
        border: 0;
        border-radius: 20px;
        background: #f7f7f7;
    }

    .right-actions {
        position: relative;
        display: flex;
        button.right-action-btn:first-child {
            margin-left: 0;
        }
        button.right-action-btn {
            width: 40px;
            height: 40px;
            margin-left: 12px;
            border: 1px solid #263a64;
            border-radius: 20px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.order-row-sm-details {
    > .show-more {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        > button {
            border: 0;
            background: none;
            padding: 10px 20px;
            &:focus {
                outline: none;
            }
            > img {
                display: inline-block;
                width: 16px;
                margin-bottom: -4px;
            }
        }
    }

    > .details-list {
        background: #fafafa;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        flex-direction: column;

        > button.list-item {
            cursor: pointer;
        }
        > .list-item {
            border: 0;
            background: none;
            padding: 20px;
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid #d8d8d8;
            &:last-child {
                border: 0;
            }

            .order-row-sm-dot {
                flex-shrink: 0;
                width: 14px;
                height: 14px;
                margin: 3px 15px 0 0;
                border: 4px solid $textAlt;
                border-radius: 14px;
                &.inprogress {
                    border-color: $progress;
                }
                &.completed {
                    border-color: #00B034;
                }
            }

            .description {
                text-align: start;
                flex-grow: 1;
                font-size: 16px;
                line-height: 19px;
                color: #323741;

                .fullDescription {
                    font-size: 13px;
                    padding: 0;
                    margin: 0;
                    color: #777;
                }
            }

            .open-report {
                border: 0;
                background: none;
                flex-shrink: 0;
                padding: 5px 10px;
                display: block;
                cursor: pointer;

                img {
                    transform: rotate(180deg);
                }
            }

            .reissue-link{
                color: #323741;
                font-size: 14px;
                cursor: pointer;
                text-decoration: underline;
                margin:0 20px 0 20px;
            }
        
            .report-block{
                display: flex;
            }
        }

        .tag{
            background: #FCF5F5
          }
    
        .tagdescription{
            text-align: left;
            color:#C94444;
            line-height: 14px;
            font-size: 14px;
            margin-top: 5px;
        }
    }
}
