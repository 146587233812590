@import 'styles/variables';

.container {
    border-radius: 3px;
    background: #f6f6f6;
    padding: 20px 23px 26px;

    label {
        .labelSection {
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        select {
            width: 100%;
            height: 45px;
            border: 1px solid #969ba5;
            border-radius: 4px;
            padding-left: 16px;
            font-size: 16px;
            appearance: none;
            background: no-repeat url('../../assets/chevron-down.svg') #fff center right 14px/16px;
        }
    }

    @media (min-width: $tablet) {
        padding: 12px 12px 12px 26px;

        label {
            display: flex;
            justify-content: space-between;

            .labelSection {
                margin-bottom: 0;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
            }

            select {
                width: 240px;
            }
        }
    }
}
